import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.css'

class MembershipPage extends React.Component {
    render() {
        return (<Layout>
            <SEO title="Membership" />
            <div className="Site-inner" data-controller="HeaderOverlay" id="yui_3_17_2_1_1538632099177_588">
                <header className="Header Header--bottom Header--overlay">
                    <div className="Header-inner Header-inner--bottom" data-nc-group="bottom">
                        <div data-nc-container="bottom-left">
                        </div>
                        <div data-nc-container="bottom-center">
                        </div>
                        <div data-nc-container="bottom-right">
                            <nav className="Header-nav Header-nav--secondary" data-nc-element="secondary-nav"
                                data-content-field="navigation">
                            </nav>
                        </div>
                    </div>
                </header>
                <div className="Content-outer" id="yui_3_17_2_1_1538632099177_224">
                    <main className="Index" data-collection-id="5b160901758d4658e6571eef" data-controller="IndexFirstSectionHeight, Parallax, IndexNavigation"
                        data-controllers-bound="IndexFirstSectionHeight, Parallax, IndexNavigation">
                        <section id="contact-us" className="Index-page" data-collection-id="5ad765962b6a288cd1072c53"
                            data-parallax-id="5ad765962b6a288cd1072c53" data-edit-main-image="Background">
                            <div className="Index-page-content ">
                                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1528241759086"
                                    id="page-5ad765962b6a288cd1072c53">
                                    <div className="row sqs-row">
                                        <div className="col sqs-col-12 span-12">
                                            <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1528212858018_25259">
                                                <div className="sqs-block-content">
                                                    <h3 className="text-align-left"><strong>We’d like you to join us as part of the Anderson Family Friend.</strong></h3>
                                                    <p className="text-align-left">Interested in becoming an Anderson Family Friend, our wine club with great member discounts?<br /><a href="https://friends.andersonfamilyvineyard.com/new/0">Join Membership</a>
                                                    </p>
                                                    <p className="text-align-left">Or, wish to receive newsletters and updates as an Anderson Family Fan?<br /><a href="https://friends.andersonfamilyvineyard.com/newsletter">Newsletter</a></p>
                                                    <br />
                                                    <h2 className="text-align-left">Membership Benefits<a className="float-right" href="/faq">FAQs</a></h2>
                                                    <ul>
                                                        <li><strong>Exclusive access.</strong> New releases, library wines and special treats.</li>
                                                        <li><strong>Selected variety.</strong> Multiple vintages carefully chosen by the winemaker for your enjoyment.</li>
                                                        <li><strong>Price reductions.</strong> Save 20% or more.</li>
                                                        <li><strong>Pay now, enjoy later.</strong> Your membership is for one year. All paid in advance with no additional charges.</li>
                                                        <li><strong>Immediate membership.</strong> Buy now at your friends’ discount.</li>
                                                        <li><strong>Easy re-ordering.</strong> Give us a quick call or e-mail and we can send you more wine at your Friends’ price.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                </div>
            </div>
        </Layout>)
    }
}

export default MembershipPage